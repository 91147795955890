import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSegmentContent,
  IonSegmentView,
  useIonRouter,
} from "@ionic/react";
import { personAddOutline, add, basketOutline } from "ionicons/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupStatus, selectCurrentGroup } from "../redux/reducer/app-slice";
import GroupOverview from "../features/group-overview";
import ExpenseOverview from "../features/expense-overview";

const Home = ({ match = { params: { groupCode: undefined } } }) => {
  const dispatch = useDispatch();
  const group = useSelector((store) => selectCurrentGroup(store));

  const { code: groupCode } = match.params;
  console.log(groupCode);
  const router = useIonRouter();

  const handleRefresh = (event) => {
    dispatch(getGroupStatus(group.id, false, event.detail.complete));
  };

  return (
    <>
      <IonContent color="light">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {group?.participants?.length > 0 && (
          <>
            <IonSegment>
              <IonSegmentButton
                value="overview"
                contentId="overview"
                onClick={() => dispatch(getGroupStatus(group.id, true))}
              >
                <IonLabel>Übersicht</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="expenses"
                contentId="expenses"
                onClick={() => dispatch(getGroupStatus(group.id, true))}
              >
                <IonLabel>Ausgaben</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <IonSegmentView>
              <IonSegmentContent id="overview">
                <GroupOverview group={group} />
              </IonSegmentContent>
              <IonSegmentContent id="expenses">
                <ExpenseOverview group={group} />
              </IonSegmentContent>
            </IonSegmentView>
          </>
        )}

        <IonFab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
          className="ion-padding"
        >
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>

          <IonFabList side="top">
            <IonFabButton
              onClick={() => router.push("/add-participants/current-group")}
            >
              <IonIcon icon={personAddOutline} />
            </IonFabButton>
            {/*  <IonFabButton>
              <IonIcon icon={cashOutline} />
            </IonFabButton> */}
            <IonFabButton onClick={() => router.push("/create-expense")}>
              <IonIcon icon={basketOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
    </>
  );
};

export default Home;
