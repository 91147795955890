export const getFormattedAmount = (numeric) => {
  if (isNaN(numeric)) {
    console.log(numeric);
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(0);
  }
  const formattedAmount = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(numeric);

  return formattedAmount;
};

export default getFormattedAmount;
