import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonRow,
  IonToolbar,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import CustomInput from "../features/custom-input";
import { useDispatch } from "react-redux";
import { joinGroup } from "../redux/reducer/app-slice";

const JoinGroup = ({ match = { params: { groupCode: "" } } }) => {
  const [code, setCode] = useState(match.params.groupCode);
  const dispatch = useDispatch();
  const [present] = useIonToast();
  const router = useIonRouter();

  const codeInput = useRef(null);

  useIonViewDidEnter(() => {
    codeInput.current?.setFocus();
  });

  const handleCodeInput = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      joinGroup(
        code,
        () => {
          router.push("/");
        },
        (errorMessage) => {
          present({
            message: errorMessage,
            duration: 1800,
            position: "bottom",
          });
        }
      )
    );
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={(e) => handleSubmit(e)}>BEITRETEN</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="primary" className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="8">
              <CustomInput>
                <IonInput
                  ref={codeInput}
                  autofocus={true}
                  type="text"
                  className="custom uppercase"
                  maxlength={6}
                  minlength={6}
                  placeholder="... ..."
                  helperText="Gib einen Code ein"
                  onIonInput={handleCodeInput}
                  onSubmit={(e) => handleSubmit(e)}
                  value={code}
                  required
                ></IonInput>
              </CustomInput>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default JoinGroup;
