import React, { useState } from "react";
import {
  IonFooter,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonActionSheet,
  useIonActionSheet,
} from "@ionic/react";
import AddGroup from "../features/add-group";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupStatus,
  removeGroup,
  selectCurrentGroupName,
  selectGroups,
} from "../redux/reducer/app-slice";
import { menuController } from "@ionic/core/components";
import { trashOutline } from "ionicons/icons";
import DownloadAppButton from "../features/download-app-button";

const AppLayout = ({
  header = true,
  footer = true,
  isHome = false,
  children,
}) => {
  const groups = useSelector((state) => selectGroups(state));
  const [present] = useIonActionSheet();
  const currentGroupName = useSelector((state) =>
    selectCurrentGroupName(state)
  );
  const dispatch = useDispatch();

  const handleGroupSwitch = (groupId) => {
    dispatch(getGroupStatus(groupId));
    menuController.close();
  };

  const handleDeleteRequest = (groupId) => {
    present({
      header: "Möchtest du die Gruppe wirklich entfernen?",
      buttons: [
        {
          text: "Entfernen",
          icon: trashOutline,
          data: {
            action: "create",
          },
          handler: () => {
            console.log("entfernt");
            dispatch(removeGroup(groupId));
            menuController.close();
            // navigateTo("/create-group");
          },
        },
        {
          text: "Abbrechen",
          role: "cancel",
          data: {
            action: "cancel",
          },
          handler: () => {
            console.log("joined");
            menuController.close();
            //navigateTo("/join");
          },
        },
      ],
    });
  };

  return (
    <>
      <IonMenu contentId="main-content">
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonTitle>Gruppen</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" color="primary">
          <IonList style={{ background: "transparent" }}>
            {groups.map((group) => {
              return (
                <IonItem color={"primary"} key={group.id} button>
                  <IonButton
                    color={"light"}
                    slot="start"
                    onClick={() => handleDeleteRequest(group.id)}
                  >
                    <IonIcon icon={trashOutline} slot="icon-only" />
                  </IonButton>
                  <IonLabel onClick={() => handleGroupSwitch(group.id)}>
                    {group.name}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
        <AddGroup />
        <DownloadAppButton />
      </IonMenu>
      <IonPage id="main-content">
        {header && (
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonMenuButton autoHide={false}></IonMenuButton>
              </IonButtons>
              <IonTitle>{!isHome && currentGroupName}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent>{children}</IonContent>
        {/*  {footer && (
          <IonFooter>
            <IonToolbar>
              <IonTitle>My App Footer</IonTitle>
            </IonToolbar>
          </IonFooter>
        )} */}
      </IonPage>
    </>
  );
};

export default AppLayout;
