export const requests = {
  createGroup: "CREATE_GROUP",
  addParticipants: "ADD_PARTICIPANTS",
};

export const requestStates = {
  notSent: "NOT_SENT",
  sent: "SENT",
  error: "ERROR",
  success: "SUCCESS",
};
