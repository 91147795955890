import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addParticipants,
  selectCurrentGroup,
} from "../redux/reducer/app-slice";
import { trashOutline } from "ionicons/icons";

const AddParticipants = (isCurrentGroup) => {
  const [inputValue, setInputValue] = useState("");
  const [participants, setParticipants] = useState([]);
  const [currentParticipants, setCurrentParticipants] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentGroup = useSelector((state) => selectCurrentGroup(state));

  useEffect(() => {
    if (isCurrentGroup) {
      setCurrentParticipants(
        currentGroup.participants.map((participant) => participant.name)
      );
    }
  }, []);

  const dispatch = useDispatch();

  const nameInput = useRef(null);

  useIonViewDidEnter(() => {
    nameInput.current?.setFocus();
  });

  const handleParticipantInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    if (inputValue) {
      setParticipants([...participants, inputValue]);
      setInputValue("");
    } else {
      if (participants.length < 1) {
        setIsOpen(true);
      } else {
        setLoading(true);
        dispatch(
          addParticipants(participants, () => {
            setLoading(false);
            window.location.replace(`/`);
          })
        );
      }
    }
    event.preventDefault();
  };

  const deleteParticipant = (name) => {
    setParticipants(participants.filter((participant) => participant !== name));
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleSubmit}>
              {inputValue === "" ? "BESTÄTIGEN" : "HINZUFÜGEN"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="primary" className="ion-padding">
        {!loading ? (
          <form onSubmit={handleSubmit}>
            <IonCard>
              <IonGrid>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12">
                    <IonInput
                      autofocus={true}
                      type="text"
                      fill="solid"
                      ref={nameInput}
                      placeholder="Name"
                      onIonInput={handleParticipantInput}
                      value={inputValue}
                      helperText="Welchen Namen hat die Person?"
                    ></IonInput>
                    <IonList inset={true}>
                      {participants.map((participant) => (
                        <IonItem key={participant}>
                          <IonLabel>{participant}</IonLabel>
                          <IonButton
                            slot="end"
                            onClick={() => deleteParticipant(participant)}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={trashOutline}
                            ></IonIcon>
                          </IonButton>
                        </IonItem>
                      ))}
                      {currentParticipants.map((participant) => (
                        <IonItem key={participant}>
                          <IonLabel>{participant}</IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </form>
        ) : (
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="1">
                <IonSpinner color={"light"} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonToast
        isOpen={isOpen}
        message="Füge Personen hinzu"
        onDidDismiss={() => setIsOpen(false)}
        duration={2000}
      ></IonToast>
    </>
  );
};

export default AddParticipants;
