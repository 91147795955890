import { IonButton } from "@ionic/react";
import React from "react";

const ShareButton = ({ label = "teilen", title = "Schau mal", text = "" }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported on this device.");
    }
  };

  return <IonButton onClick={handleShare}>{label}</IonButton>;
};

export default ShareButton;
