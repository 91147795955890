export const getShareMessage = (code, groupName) => {
  const string = `Tritt unserer Gruppe "${groupName}" bei.

Öffne diesen Link auf deinem Smartphone: https://split-free.bjoernpeter.de/join/${code}

Oder gib folgenden Code auf https://split-free.bjoernpeter.de/ ein: ${code}`;

  return string;
};

export default getShareMessage;
