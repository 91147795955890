import { createSlice } from "@reduxjs/toolkit";
import { requests, requestStates } from "../../constants/requests";

const initialState = {
  groups: [],
  session: {
    id: null,
    selectedGroup: null,
  },
  requestStates: {},
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedGroup: (state, action) => {
      state.session.selectedGroup = action.payload;
    },
    setRequestState: (state, action) => {
      state.requestStates = { ...state.requestStates, ...action.payload };
    },
    setSessionId: (state, action) => {
      state.session.id = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
});

export const createGroup = (name, onSuccess) => (dispatch, getState) => {
  const sessionId = selectSessionId(getState());
  fetch(`${process.env.REACT_APP_API_URL}/groups`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Session-ID": sessionId,
    },
    body: JSON.stringify({ name }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((group) => {
      dispatch(setSelectedGroup(group));
      onSuccess();
    });
};

export const addParticipants =
  (participants, onSuccess) => (dispatch, getState) => {
    const sessionId = selectSessionId(getState());
    const currentGroupId = selectCurrentGroupId(getState());

    if (currentGroupId) {
      dispatch(
        setRequestState({ [requests.addParticipants]: requestStates.sent })
      );
      fetch(
        `${process.env.REACT_APP_API_URL}/groups/${currentGroupId}/participants`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Session-ID": sessionId,
          },
          body: JSON.stringify({ participants: participants }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((participants) => {
          getGroups(dispatch, sessionId);
          fetch(`${process.env.REACT_APP_API_URL}/groups/${currentGroupId}`, {
            headers: {
              "Session-ID": sessionId,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((group) => {
              dispatch(setSelectedGroup(group));
              onSuccess();
            });
        });
    }
  };

const getGroups = async (dispatch, sessionId) => {
  fetch(`${process.env.REACT_APP_API_URL}/groups`, {
    headers: {
      "Session-ID": sessionId,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((groups) => {
      dispatch(setGroups(groups));
    });
};

export const getGroupStatus =
  (groupId, silent = false, onSuccess = () => {}) =>
  (dispatch, getState) => {
    const sessionId = selectSessionId(getState());

    try {
      fetch(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
        headers: {
          "Session-ID": sessionId,
        },
      })
        .then((response) => {
          if (!response.ok && !silent) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((group) => {
          dispatch(setSelectedGroup(group));
          onSuccess();
        })
        .catch((error) => {
          if (!silent) {
            throw new Error(error);
          }
        });
    } catch (error) {
      if (!silent) {
        throw new Error(error.message);
      }
    }
  };

export const joinGroup =
  (hash, onSuccess, onError) => async (dispatch, getState) => {
    const sessionId = selectSessionId(getState());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/join/${hash}`,
        {
          headers: {
            "Session-ID": sessionId,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 404) {
          const { code, message } = await response.json();
          console.log(code);
          onError(message);
        } else {
          throw new Error("Network response was not ok");
        }
      } else {
        const group = await response.json();
        dispatch(setSelectedGroup(group));

        getGroups(dispatch, sessionId);

        onSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

export const removeGroup = (groupId) => async (dispatch, getState) => {
  const sessionId = selectSessionId(getState());

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/groups/${groupId}`,
      {
        method: "DELETE",
        headers: {
          "Session-ID": sessionId,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    dispatch(setSelectedGroup(null));

    getGroups(dispatch, sessionId);
  } catch (error) {
    console.log(error);
  }
};

export const createExpense =
  (expense, onSuccess, onError) => (dispatch, getState) => {
    const sessionId = selectSessionId(getState());
    const currentGroupId = selectCurrentGroupId(getState());

    console.log(expense);
    fetch(
      `${process.env.REACT_APP_API_URL}/groups/${currentGroupId}/expenses`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Session-ID": sessionId,
        },
        body: JSON.stringify({ expense: expense }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          onError(
            `Das hat nicht funktioniert. Der Server hat mit ${response.status} geantwortet: ${response.statusText}`
          );
          return;
        }
        return response.json();
      })
      .then((group) => {
        // get new data here
        dispatch(setSelectedGroup(group));
        onSuccess();
      });
  };

export const updateExpense =
  (expenseId, expense, onSuccess, onError) => (dispatch, getState) => {
    const sessionId = selectSessionId(getState());
    const currentGroupId = selectCurrentGroupId(getState());

    console.log(expense);
    fetch(
      `${process.env.REACT_APP_API_URL}/groups/${currentGroupId}/expenses/${expenseId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Session-ID": sessionId,
        },
        body: JSON.stringify({ expense: expense }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          onError(
            `Das hat nicht funktioniert. Der Server hat mit ${response.status} geantwortet: ${response.statusText}`
          );
          return;
        }
        return response.json();
      })
      .then((group) => {
        // get new data here
        dispatch(setSelectedGroup(group));
        onSuccess();
      });
  };

export const createSessionId = () => (dispatch, getState) => {
  const sessionId = selectSessionId(getState());

  if (!sessionId) {
    const generateRandomString = function (length) {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    dispatch(setSessionId(generateRandomString(16)));
  }
};

export const selectSessionId = (state) => state.session.id;
export const selectCurrentGroupId = (state) => state.session.selectedGroup?.id;
export const selectCurrentGroupName = (state) =>
  state.session.selectedGroup?.name;

export const selectCurrentGroup = (state) => state.session.selectedGroup;

export const selectRequestState = (state, requestName) => {
  if (state.requestStates[requestName]) {
    return state.requestStates[requestName];
  }
  return requestStates.notSent;
};

export const selectGroups = (state) => state.groups;

export const { setSelectedGroup, setGroups, setRequestState, setSessionId } =
  appSlice.actions;

export default appSlice.reducer;
