import { IonButton, IonIcon } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { downloadOutline } from "ionicons/icons";

const DownloadAppButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event to trigger later
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const choiceResult = await deferredPrompt.userChoice;
      console.log("User choice:", choiceResult.outcome);
      setDeferredPrompt(null); // Reset the prompt
    }
  };

  return (
    deferredPrompt && (
      <div>
        <IonButton
          expand="block"
          shape="round"
          color="primary"
          className="ion-text-capitalize ion-margin"
          onClick={handleInstallClick}
        >
          <IonIcon slot="start" icon={downloadOutline} />
          App Installieren
        </IonButton>
      </div>
    )
  );
};

export default DownloadAppButton;
