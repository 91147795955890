import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import getFormattedAmount from "../utils/get-formatted-amount";

const ExpenseOverview = ({ group: { expenses = [], participants = [] } }) => {
  const router = useIonRouter();

  return expenses.length === 0 ? (
    "Noch keine Ausgaben"
  ) : (
    <IonList>
      {expenses.map((expense) => {
        return (
          <IonItem
            key={expense.id}
            button
            onClick={() => router.push(`/e/${expense.id}`)}
          >
            <div>
              {expense.name}
              <br />
              <IonNote>
                von{" "}
                {
                  participants.find(
                    (participant) => participant.id === expense.payer
                  ).name
                }
              </IonNote>
            </div>
            <IonLabel slot="end">
              {getFormattedAmount(expense.amount_in_decimals / 100)}
            </IonLabel>
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default ExpenseOverview;
