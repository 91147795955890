import { setupIonicReact, IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./scenes/home";
import { Route } from "react-router-dom";
import AppLayout from "./scenes/app-layout";
import JoinGroup from "./scenes/join-group";
import CreateGroup from "./scenes/create-group";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { createSessionId } from "./redux/reducer/app-slice";
import AddParticipants from "./scenes/add-participants";
import CreateExpense from "./scenes/create-expense";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";

import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createSessionId());
  }, []);

  setupIonicReact();
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Switch>
            <Route
              path={["/join/:groupCode", "/join"]}
              exact={false}
              strict={false}
              component={(router) => (
                <AppLayout header={false}>
                  <JoinGroup match={router.match} />
                </AppLayout>
              )}
            />
            <Route
              path="/create-group"
              exact={true}
              component={() => (
                <AppLayout header={false}>
                  <CreateGroup />
                </AppLayout>
              )}
            />
            <Route
              path={"/add-participants/current-group"}
              exact={true}
              component={() => (
                <AppLayout header={false}>
                  <AddParticipants isCurrentGroup />
                </AppLayout>
              )}
            />
            <Route
              path={"/add-participants"}
              exact={true}
              component={() => (
                <AppLayout header={false}>
                  <AddParticipants />
                </AppLayout>
              )}
            />
            <Route
              path="/e/:expenseId"
              exact={true}
              component={(router) => (
                <AppLayout header={false}>
                  <CreateExpense match={router.match} />
                </AppLayout>
              )}
            />
            <Route
              path="/create-expense"
              exact={true}
              component={() => (
                <AppLayout header={false}>
                  <CreateExpense />
                </AppLayout>
              )}
            />

            <Route
              path="/groups/:groupCode"
              component={(router) => (
                <AppLayout>
                  <Home match={router.match} />
                </AppLayout>
              )}
            />

            <Route
              path="/"
              exact={true}
              component={() => (
                <AppLayout>
                  <Home />
                </AppLayout>
              )}
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
