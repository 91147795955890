import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRippleEffect,
  useIonToast,
} from "@ionic/react";
import React, { useState } from "react";
import getFormattedAmount from "../utils/get-formatted-amount";
import { copyOutline } from "ionicons/icons";
import ShareButton from "./share-button";
import getShareMessage from "../utils/get-share-message";

const GroupOverview = ({
  group: { participants = [], hash = "", name = "" },
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [present] = useIonToast();

  const handleHashHighlight = () => {
    navigator.clipboard
      .writeText(hash)
      .then(() => {
        present({
          message: "Code kopiert",
          duration: 800,
          position: "bottom",
        });
      })
      .catch((err) => console.error("Error copying text: ", err));
  };

  return (
    <>
      <IonList>
        {participants.map((participant) => (
          <IonItem key={participant.id}>
            <IonLabel>{participant.name}</IonLabel>
            <IonLabel slot="end">
              {getFormattedAmount(
                (parseFloat(participant.gets) - parseFloat(participant.pays)) /
                  100
              )}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
      <div className="ion-text-center ion-justify-content-center">
        <IonButton shape="round" onClick={() => setIsShareModalOpen(true)}>
          Einladen
        </IonButton>
      </div>
      <IonModal
        id="invite-modal"
        className="custom-modal"
        isOpen={isShareModalOpen}
        onIonModalDidDismiss={() => setIsShareModalOpen(false)}
      >
        <div className="hash-highlight-container">
          <div
            role="button"
            className="hash-highlight ion-activatable ripple-parent rounded-rectangle"
            onClick={handleHashHighlight}
          >
            <span style={{ marginRight: "0.5rem" }}>{hash}</span>{" "}
            <IonIcon icon={copyOutline} />
            <IonRippleEffect></IonRippleEffect>
          </div>
          <p>Mit diesem Code kannst du andere in deine Gruppe einladen.</p>
          <ShareButton
            label="teilen"
            text={getShareMessage(hash, name)}
            title="Split-Free Gruppeneinladung"
          />
        </div>
      </IonModal>
    </>
  );
};

export default GroupOverview;
