import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
  IonToolbar,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addParticipants, createGroup } from "../redux/reducer/app-slice";
import { trashOutline } from "ionicons/icons";

const CreateGroup = () => {
  const [groupName, setGroupName] = useState("");
  const [groupNameSubmitted, setGroupNameSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [participants, setParticipants] = useState([]);
  const [presentToast] = useIonToast();
  const router = useIonRouter();

  const showToast = (message) => {
    presentToast({
      message,
      duration: 1500,
      position: "bottom",
    });
  };

  const dispatch = useDispatch();

  const nameInput = useRef(null);

  useIonViewDidEnter(() => {
    nameInput.current?.setFocus();
  });

  const handleNameInput = (event) => {
    setGroupName(event.target.value);
  };

  const handleParticipantInput = (event) => {
    setInputValue(event.target.value);
  };

  const deleteParticipant = (name) => {
    setParticipants(participants.filter((participant) => participant !== name));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!groupNameSubmitted) {
      if (groupName === "") {
        showToast("Gib einen Namen ein.");
        setGroupNameSubmitted(false);
      } else {
        dispatch(createGroup(groupName, () => {}));
        setGroupNameSubmitted(true);
      }
    } else {
      if (inputValue) {
        setParticipants([...participants, inputValue]);
        setInputValue("");
      } else {
        if (participants.length < 1) {
          showToast("Füge Personen hinzu");
        } else {
          dispatch(
            addParticipants(participants, () => {
              router.push("/");
            })
          );
        }
      }
    }
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={(e) => handleSubmit(e)}>WEITER</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="primary" className="ion-padding">
        <form onSubmit={(e) => handleSubmit(e)}>
          <IonCard>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                {!groupNameSubmitted ? (
                  <IonCol size="12">
                    <IonInput
                      ref={nameInput}
                      type="text"
                      fill="solid"
                      placeholder="Name"
                      onIonInput={handleNameInput}
                    ></IonInput>
                    <IonList>
                      <IonItem>
                        <IonToggle disabled={true}>
                          Nur auf diesem Gerät speichern
                        </IonToggle>
                      </IonItem>
                    </IonList>
                  </IonCol>
                ) : (
                  <IonCol size="12">
                    <IonInput
                      type="text"
                      fill="solid"
                      ref={nameInput}
                      placeholder="Name"
                      onIonInput={handleParticipantInput}
                      value={inputValue}
                      helperText="Welchen Namen hat die Person?"
                    ></IonInput>
                    <IonList inset={true}>
                      {participants.map((participant) => (
                        <IonItem key={participant}>
                          <IonLabel>{participant}</IonLabel>
                          <IonButton
                            slot="end"
                            onClick={() => deleteParticipant(participant)}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={trashOutline}
                            ></IonIcon>
                          </IonButton>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCard>
        </form>
      </IonContent>
    </>
  );
};

export default CreateGroup;
